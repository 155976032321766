import React from "react";
import theme from "theme";
import { Theme, Text, Box, Hr, Strong, Span, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"poslugi"} />
		<Helmet>
			<title>
				Trailblaze24
			</title>
			<meta name={"description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:title"} content={"Trailblaze24"} />
			<meta property={"og:description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" margin="0px 0px 0px 0px" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				padding="0px 40px 0px 15px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
				lg-margin="0px 0px 60px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="#ffffff"
					font="--lead"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-transform="uppercase"
				>
					усе для вашого драйву
				</Text>
				<Text margin="0px 0px 24px 0px" color="#ffffff" font="--headline1" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Наші послуги
				</Text>
				<Text margin="0px 0px 0px 0px" color="--lightD2" font="--lead">
					Trailblaze24 надає широкий спектр послуг для задоволення найрізноманітніших запитів наших клієнтів. Ми створюємо умови, щоб ваша пригода була максимально комфортною, безпечною та яскравою.
				</Text>
			</Box>
			<Box
				display="flex"
				flex-direction="column"
				margin="0px 0px 0px 0px"
				width="50%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-width="100%"
			>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2">
							<Span
								font="--headline3"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong>
									Оренда квадроциклів
								</Strong>
							</Span>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 16px 0px" color="#ffffff" font="--lead">
							<Strong>
								Тариф "Прогулянковий":
							</Strong>
							{" "}2 години катання для тих, хто хоче розслабитися та насолодитися краєвидами.
							<br />
							<br />
							<Strong>
								Тариф "Екстрим":
							</Strong>
							{" "}4 години драйву на складних трасах.
							<br />
							<br />
							<Strong>
								Денні тури:
							</Strong>
							{" "}Проведіть цілий день за кермом квадроцикла, відкриваючи мальовничі місця.
						</Text>
						<Text margin="0px 0px 0px 0px" color="--lightD2" font="--lead">
							Виберіть з нашого парку сучасних квадроциклів, які ідеально підходять як для новачків, так і для досвідчених райдерів. Пропонуємо моделі з автоматичною коробкою передач для простоти управління та моделі для екстремальних любителів.
							<br />
							<br />
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2">
							<Span
								font="--headline3"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong>
									Організація корпоративних заходів
								</Strong>
							</Span>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--lightD2" font="--lead">
							Шукаєте незвичайний спосіб провести корпоратив чи тімбілдинг? Ми пропонуємо маршрути для великих груп, а також індивідуальний підхід до кожного клієнта. Обіцяємо, ваша команда запам'ятає цей день надовго!
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2">
							<Span
								font="--headline3"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong>
									Подарункові сертифікати
								</Strong>
							</Span>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--lightD2" font="--lead">
							Не знаєте, чим здивувати друзів або рідних? Подаруйте їм сертифікат на незабутню пригоду! Це ідеальний подарунок для любителів активного відпочинку.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2">
							<Span
								font="--headline3"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong>
									Екскурсійні маршрути
								</Strong>
							</Span>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--lightD2" font="--lead">
							Наші досвідчені гіди проведуть вас наймальовничішими маршрутами, розкажуть про історію та унікальність регіону. У нас є маршрути через ліси, гори та узбережжя.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" margin="0px 0px 48px 0px">
					<Box display="flex" align-items="center" margin="0px 0px 16px 0px">
						<Hr
							width="54px"
							margin="0px 50px 0px 0px"
							border-color="--color-lightD2"
							border-width="2px 0 0 0 "
							opacity="0.2"
							sm-margin="0px 20px 0px 0px"
							sm-width="44px"
						/>
						<Text margin="0px 0px 0px 0px" color="#ffffff" font="--headline2">
							<Span
								font="--headline3"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Strong>
									Навчання їзді на квадроциклах
								</Strong>
							</Span>
						</Text>
					</Box>
					<Box padding="0px 0px 0px 104px" sm-padding="0px 0px 0px 64px">
						<Text margin="0px 0px 0px 0px" color="--lightD2" font="--lead">
							Ніколи не сиділи за кермом квадроцикла? Не проблема! Наші інструктори навчать вас усім тонкощам водіння, включаючи техніку безпеки та особливості керування.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footernew />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f21f141a0cf0024a5b026"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});